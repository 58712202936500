import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IAccount } from "../../apis/types";
import { IcPhone, IcEmail } from "../../assets/images";
import Input from "../../components/input/input";
import TitleComponent from "../../components/title/title";
import useEmailAutocomplete from "../../hooks/useEmailAutocomplete";
import { AsYouType } from "libphonenumber-js";
import "./billing-info.scss";

export interface IError {
  email: string;
  phone: string;
}

export interface IBillingInformationProps {
  error: IError;
  account: IAccount;
  setAccount: (acc: IAccount) => void;
}

export default function BillingInformation(props: IBillingInformationProps) {
  const { error, account, setAccount } = props;
  const { t } = useTranslation();

  const { email, onChange: handleEmailChange, bind } = useEmailAutocomplete();

  const onChange = (name: string, value: string) => {
    setAccount({ ...account, [name]: value });
  };

  const onChangeEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.name, e.target.value);
    handleEmailChange(e);
  };

  useEffect(() => {
    setAccount({ ...account, email: email.address });
  }, [email.address]);

  return (
    <>
      <TitleComponent text={t("title.billingInfo")} />
      <form>
        <div className="info">
          <Input
            name="email"
            label={t("placeholder.email") as string}
            value={account.email}
            onChange={(e) => onChangeEmail(e)}
            propsEmailAutocompleted={bind}
            icon={IcEmail}
          />
          {error.email && <div className="error-msg">{error.email}</div>}
          <Input
            type="tel"
            name="phone"
            label={t("placeholder.phoneNumber") as string}
            value={new AsYouType().input(account.phone)}
            onChange={(e) => onChange(e.target.name, e.target.value)}
            icon={IcPhone}
          />
          {error.phone && <div className="error-msg">{error.phone}</div>}
        </div>
      </form>
    </>
  );
}
