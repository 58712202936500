import React, {useContext, useMemo, useState} from "react";
import { useTranslation } from "react-i18next";
import "./events.scss";
import {defaultEvents, EventContext, EventTypeParam, IEventObject} from "../../contexts/event-context";
import ItemEvent from "../../components/item-event/item-event";
import {EEventType} from "../../constants/event-type";
import {IconNoEvent} from "../../assets/images";

export interface IEventProps {}

export default function Events(props: IEventProps) {
    const { onGetEvents, activeTab, upcomingEvents, ongoingEvents, onChangeActiveTab, loadedEvents } = useContext(EventContext);
    const { t } = useTranslation();
    const [data, setData] = useState<IEventObject>(defaultEvents)

    useMemo(() => {
        if (activeTab === EEventType.UPCOMING) {
            setData(upcomingEvents);
        } else if (activeTab === EEventType.ONGOING) {
            setData(ongoingEvents);
        }
    }, [upcomingEvents, ongoingEvents]);

    const fetchMore = () => {
        if (activeTab == EEventType.UPCOMING) {
            onGetEvents({
                date: EventTypeParam.upcoming,
                limit: upcomingEvents.limit,
                page: upcomingEvents.page += 1
            });
        } else if (activeTab == EEventType.ONGOING) {
            onGetEvents({
                date: EventTypeParam.ongoing,
                limit: ongoingEvents.limit,
                page: ongoingEvents.page += 1
            });
        }
    }

    const changeEventTab = (tabName: string) => {
        onChangeActiveTab(tabName);
    }

    const handleLoad = (e: any) => {
        const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
        if (bottom) {
            fetchMore();
        }
    };

    return (
        <>
            {loadedEvents && (
                <>
                    <ul className="event-tabs">
                        <li className={`event-tab ${activeTab === EEventType.UPCOMING ? 'active' : 'inactive'}`}
                            onClick={() => changeEventTab(EEventType.UPCOMING)}
                        >
                            {t("event.upcoming")}
                        </li>
                        <li className={`event-tab ${activeTab === EEventType.ONGOING ? 'active' : 'inactive'}`}
                            onClick={() => changeEventTab(EEventType.ONGOING)}
                        >
                            {t("event.ongoing")}
                        </li>
                    </ul>
                    {
                        data.events.length > 0 ?
                            (
                                <div className="list-event" onScroll={handleLoad}>
                                    {data.events.map((event, index) => <ItemEvent key={event._id} event={event} />)}
                                </div>
                            )
                            :
                            (
                                <div className="list-event">
                                    <div className="no-event">
                                        <div className="no-event-icon">
                                            <img src={IconNoEvent} />
                                        </div>
                                        <div className="no-event-title">{t("noEvents")}</div>
                                    </div>
                                </div>
                            )

                    }
                </>
            )}
        </>
    );
}