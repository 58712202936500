import React, { useContext } from "react";
import { IconCart } from "../../assets/images";
import { TicketContext } from "../../contexts/ticket-context";
import "./cart.scss";

export default function Cart() {
  const { tickets, currency, event } = useContext(TicketContext);

  const getCost = () => {
    let cost = 0;
    tickets.forEach((item) => {
      if (event.includeVatToTicketPrice) {
        const priceIncludeVat = item.vat
          ? item.price + item.price * item.vat
          : ((item.price + item.price * event.vatOfTicket) as number);
        cost += item.count * priceIncludeVat;
      } else {
        cost += item.count * item.price;
      }
    });

    return parseFloat(cost.toString()).toFixed(2);
  };

  return (
    <div className="cart-section">
      <img className="cart-icon" src={IconCart} />
      <div className="cart-value">
        {currency ?? ""} {getCost()}
      </div>
    </div>
  );
}
