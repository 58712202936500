import "./textInput.scss";

export default function TextInput(props: any) {
  const { question, answer, placeholder } = props.data;
  const { onChange, blockIndex, itemIndex } = props;

  const handleChange = (event: any) => {
    onChange(blockIndex, itemIndex, event.target.value);
  };
  return (
    <div className="wrapperInputText">
      <div className="questionInputText">
        {question}
        <span style={{ color: "#EC8297" }}> *</span>
      </div>
      <div className="form-text-input">
        <input
          type="input"
          className="input-field"
          placeholder={placeholder}
          value={answer}
          onChange={handleChange}
        />
      </div>
    </div>
  );
}
