import React, { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Android, AppStore } from "../../assets/images";
import Source from "../../constants/source";
import { ETheme } from "../../constants/theme";
import { ThemeContext } from "../../contexts/theme-context";
import "./ticketClose.scss";

export default function TicketClose() {
    const { theme } = useContext(ThemeContext);
    const { t } = useTranslation();
    const onDownload = (type: string) => {
        const linkURL = document.createElement("a");

        if (type === "android") linkURL.href = Source.android;
        else linkURL.href = Source.appStore;

        linkURL.setAttribute("target", "_blank");
        document.body.appendChild(linkURL);
        linkURL.click();
    };
    return (
        <div className="thank-you-container">
            <div className="thank-you">
                <div
                    className={`retrieve-title ${theme !== ETheme.DARK && "retrieve-title-light"
                        }`}
                >
                    {t("ticketSoled")}
                </div>
            </div>
            <div className="eventComing">{t("comingEvent")}</div>
            <div className="download">
                <img
                    src={Android}
                    alt="Android"
                    onClick={() => onDownload("android")}
                />
                <img
                    src={AppStore}
                    alt="AppStore"
                    onClick={() => onDownload("appstore")}
                />
            </div>
        </div>
    )
}