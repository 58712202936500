import { useState, useRef, useCallback, useEffect } from "react";

const defaultDomains = [
  "yahoo.com",
  "hotmail.com",
  "gmail.com",
  "me.com",
  "aol.com",
  "mac.com",
  "live.com",
  "googlemail.com",
  "msn.com",
  "facebook.com",
  "verizon.net",
  "outlook.com",
  "icloud.com",
  "table.co",
  "fb.com",
];

export default function useEmailAutocomplete({
  domains = [],
  validation = true,
} = {}) {
  const theDomains = [...(domains || []), ...defaultDomains];
  const prevEmail = useRef<string>("");
  const prevVal = useRef("");
  const container = useRef();
  const input = useRef<any>();
  const email = useRef<string>("");
  const [, forceUpdate] = useState(false);

  const findInput = useCallback((element: any): any => {
    if (element && element.tagName === "INPUT") return element;
    if (element && element.children && element.children.length > 0) {
      for (const child of element.children) {
        const potentialInput = findInput(child);
        if (potentialInput) return potentialInput;
      }
    }
  }, []);

  useEffect(() => {
    input.current = findInput(container.current);
    if (!input.current)
      console.error(
        "There is no input in the component you're trying to attach useEmailAutocomplete to"
      );
  }, [findInput]);

  const suggest = useCallback(
    (email: string) => {
      const [, /* emailName */ partialDomain] = email.split("@");
      if (!partialDomain || email.length <= prevVal.current.length) return "";
      const domain =
        theDomains.find((d) => d.indexOf(partialDomain) === 0) || "";
      return domain.replace(partialDomain, "");
    },
    [theDomains]
  );

  const onChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      // works for strings, and objects, not for numbers
      if (!e.hasOwnProperty("target")) {
        return console.error("NOT IMPLEMENTED YET");
      }
      const { value } = e.target;
      const suggestion = suggest(value);
      const theEmail = value + suggestion;
      email.current = theEmail;
      forceUpdate((x) => !x);
      if (suggestion) highlight(suggestion);
      prevEmail.current = theEmail;
      prevVal.current = value;
    },
    [suggest]
  );

  function highlight(suggestion: string) {
    setTimeout(() => {
      const email = prevVal.current + suggestion;
      const startPos = email.lastIndexOf(suggestion);
      const endPos = startPos + suggestion.length;
      input.current.setSelectionRange(startPos, endPos);
    }, 0);
  }

  const doValidationCheck = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (validation) {
        forceUpdate((x) => !x);
      }
    },
    [email, validation]
  );

  const htmlAttributes = {
    value: email.current,
    onChange,
    ref: container,
    onBlur: doValidationCheck,
    onFocus: doValidationCheck,
  };

  return {
    email: new Proxy(
      { address: email },
      { get: (obj: any, key) => obj[key].current }
    ),
    ...htmlAttributes,
    bind: htmlAttributes,
  };
}
