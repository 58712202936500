import { ITokenResponse } from "../apis/types";
import { ETheme } from "../constants/theme";

const accessTokenKey = "access_token";
const refreshTokenKey = "refresh_token";
const userIdKey = "user_id";
const organizationIdKey = "organization_id";
const eventId = "event_id";
const theme = "theme";
const email = "email";

export function storeToken(token: ITokenResponse) {
  localStorage.setItem(accessTokenKey, token.access_token);
  localStorage.setItem(refreshTokenKey, token.refresh_token);
}

export function getAccessToken() {
  return localStorage.getItem(accessTokenKey);
}

export function getRefreshToken() {
  return localStorage.getItem(refreshTokenKey);
}

export function storeUserId(userId: string) {
  localStorage.setItem(userIdKey, userId);
}

export function setOrganizationId(userId: string) {
  localStorage.setItem(organizationIdKey, userId);
}

export function getUserId() {
  return localStorage.getItem(userIdKey);
}

export function getOrganizationId() {
  return localStorage.getItem(organizationIdKey) || "";
}

export function setUrl(url: string) {
  return localStorage.setItem("url", url);
}
export function getUrl() {
  return localStorage.getItem("url");
}

export function setThemeLocal(value: ETheme) {
  return localStorage.setItem(theme, value);
}
export function getThemeLocal() {
  return localStorage.getItem(theme);
}

export function setEmailLocal(value: string) {
  return localStorage.setItem(email, value);
}
export function getEmailLocal() {
  return localStorage.getItem(email);
}
