import React, {
  memo,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { ThemeContext } from "../../contexts/theme-context";
import i18n from "../../i18n";
import { ProgressBar, Step } from "../step-process";
import { CircleIndexProcess, TextProcess } from "./styles";

export interface IProgressBarComponentProps {
  step: number;
  setStep: (step: number) => void;
  isAddon: boolean;
  stepData: string[];
}

const ProgressBarComponent = (props: IProgressBarComponentProps) => {
  const { step, setStep, isAddon, stepData } = props;
  const { theme } = useContext(ThemeContext);
  const [steps, setSteps] = useState([
    {
      status: "1",
    },
    {
      status: "2",
    },
    {
      status: "3",
    },
  ]);
  useEffect(() => {
    if (isAddon) {
      setSteps([
        {
          status: "1",
        },
        {
          status: "2",
        },
        {
          status: "3",
        },
      ]);
    } else {
      setSteps([
        {
          status: "1",
        },
        {
          status: "2",
        },
      ]);
    }
  }, [isAddon]);

  const textProcess = (index: number) => {
    switch (stepData[index]) {
      case "tickets":
        return i18n.t("step.tickets");
      case "addons":
        return i18n.t("step.addons");
      case "question":
        return "questions";
      case "checkout":
        return i18n.t("step.checkout");
      default:
        break;
    }
  };

  const getStepPosition = (step: number) => {
    switch (stepData[step]) {
      case "tickets":
        return 1 / ((stepData?.length - 3) * 2);
      case "addons":
        return 1 / ((stepData?.length - 3) * 2) + 1 / (stepData?.length - 3);
      case "question":
        return 1 - 1 / ((stepData?.length - 3) * 2);
      case "checkout":
        return 1;
    }
    // switch (step) {
    //   case 0:
    //     if (isAddon) {
    //       return 1 / 4;
    //     } else {
    //       return 1 / 2;
    //     }
    //   case 1:
    //     if (isAddon) {
    //       return 3 / 4;
    //     } else {
    //       return 1;
    //     }
    //   case 2:
    //     return 1;
    //   default:
    //     return 1 / 4;
    // }
  };

  return (
    <ProgressBar
      height={2}
      percent={100 * Number(getStepPosition(step))}
      filledBackground={`var(--primary)`}
      unfilledBackground={`var(--secondary)`}
    >
      {stepData.slice(0, stepData.length - 2).map((item, index) => {
        return (
          <Step
            key={item}
            position={100 * Number(getStepPosition(step))}
            transition="scale"
            accomplished={true}
            index={index}
            onClick={() => setStep(index)}
          >
            {({ index }) => (
              <React.Fragment>
                <CircleIndexProcess accomplished={index <= step ? true : false}>
                  {index + 1}
                </CircleIndexProcess>
                <TextProcess
                  accomplished={index <= step ? true : false}
                  theme={theme}
                >
                  {textProcess(index)}
                </TextProcess>
              </React.Fragment>
            )}
          </Step>
        );
      })}
    </ProgressBar>
  );
};

export default memo(ProgressBarComponent);
