import styled from "styled-components";
import { ETheme } from "../../constants/theme";

export interface TextProcessProps {
  accomplished: boolean;
  theme: ETheme;
  children?: React.ReactNode | undefined;
}
export const TextProcess = styled.p<TextProcessProps>`
  font-weight: ${(props) => (props.theme === ETheme.DARK ? 700 : 400)};
  font-size: ${(props) => (props.theme === ETheme.DARK ? "14px" : "16px")};
  line-height: 20px;
  text-align: center;
  text-transform: uppercase;
  opacity: ${(props) => (props.accomplished ? "1" : "0.6")};
  color: var(--text-title);
  padding-top: 12px;
  @media (max-width: 490px) {
    font-size: ${(props) => (props.theme === ETheme.DARK ? "14px" : "10px")};
    line-height: 14px;
    padding-top: 8px;
  }
`;

export interface CircleIndexProcessProps {
  accomplished: boolean;
  children?: React.ReactNode | undefined;
}
export const CircleIndexProcess = styled.div<CircleIndexProcessProps>`
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-weight: 500;
  color: ${(props) =>
    props.accomplished ? `var(--white)` : `var(--text-processing)`};
  border: ${(props) =>
    props.accomplished ? "none" : `3px solid var(--secondary)`};
  background-color: ${(props) =>
    props.accomplished ? `var(--primary)` : `var(--background)`};

  @media (max-width: 490px) {
    width: 24px;
    height: 24px;
    font-size: 14px;
    border: ${(props) =>
      props.accomplished ? "none" : `2px solid var(--secondary)`};
  }
`;
