const Source = {
  termConditional: "https://chi.app",

  appStore: "https://apps.apple.com/us/app/chi-app/id1473832026",
  android: "https://play.google.com/store/apps/details?id=com.chi.app",

  website: "https://chi.app",
};

export default Source;
