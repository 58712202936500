import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "./styles/app.scss";
import reportWebVitals from "./reportWebVitals";
import RoutesComponent from "./routes";
import { BrowserRouter } from "react-router-dom";
import LoadingProvider from "./contexts/loading-context";
import TicketProvider from "./contexts/ticket-context";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import ThemeProvider from "./contexts/theme-context";
import { HelmetProvider } from "react-helmet-async";
import "./i18n";
import EventProvider from "./contexts/event-context";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <HelmetProvider>
      <BrowserRouter>
        <ThemeProvider>
          <LoadingProvider>
            <EventProvider>
              <TicketProvider>
                <ToastContainer />
                <RoutesComponent />
              </TicketProvider>
            </EventProvider>
          </LoadingProvider>
        </ThemeProvider>
      </BrowserRouter>
    </HelmetProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
