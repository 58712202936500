export function validateEmail(email: string) {
  const emailRegex =
    /^([a-zA-Z0-9_\.\-\+])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
  return emailRegex.test(String(email).toLowerCase());
}

export const validatePhone = (phone: string) => {
  const phoneRegex =
    /^[+]?[(]?[0-9]{3,4}[)]?[-\s.]?[0-9]{3,4}[-\s.]?[0-9]{4,6}$/;
  return phoneRegex.test(phone);
};
