import "./area.scss";

export default function TextArea(props: any) {
  const { question, answer } = props.data;
  const { onChange, blockIndex, itemIndex } = props;

  const handleChange = (event: any) => {
    onChange(blockIndex, itemIndex, event.target.value);
  };
  return (
    <div className="wrapperAreaText">
      <div className="questionArea">{question}</div>
      <textarea className="form-text-area" rows={3} onChange={handleChange}>
        {answer}
      </textarea>
    </div>
  );
}
