import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import ItemTicket from "../../components/item-ticket/item-ticket";
import TitleComponent from "../../components/title/title";
import { TicketContext } from "../../contexts/ticket-context";
import "./addons.scss";
import Cart from "../../components/cart/cart";

export interface IAddonsProps {}

export default function Addons(props: IAddonsProps) {
  const { tickets } = useContext(TicketContext);
  const { t } = useTranslation();
  return (
    <>
        <div className="addons-ticket-header">
            <div className="ticket-header">
                <div className="left-ticket-header">
                    <TitleComponent text={t("title.addonOption")} />
                </div>
                <div className="right-ticket-header">
                    <Cart />
                </div>
            </div>
        </div>
      <div className="list-ticket">
        {tickets
          .filter((item) => item.isAddOn)
          .map((item) => (
            <ItemTicket key={item._id} ticket={item} />
          ))}
      </div>
    </>
  );
}
