import React, { useState } from "react";
import "./input.scss";

export interface IInputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  name: string;
  propsEmailAutocompleted?: any;
  icon?: string;
}

export default function Input(props: IInputProps) {
  const { propsEmailAutocompleted, label, name, icon, ...inputProps } = props;
  const combineProps = propsEmailAutocompleted
    ? { ...inputProps, ...propsEmailAutocompleted }
    : { ...inputProps };

  return (
    <div className="form-group">
      <label htmlFor={name}>
        <img src={icon} className="imageIcon" alt="icon" />
      </label>
      <input
        type="input"
        className="form-field"
        name={name}
        required
        placeholder={label}
        id={name}
        {...(combineProps as any)}
      />
    </div>
  );
}
