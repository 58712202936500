import React from "react";
import "./loader.scss";

const Spinner = () => {
  return (
    <div className="spinDiv">
      <div className="spinStyled" />
    </div>
  );
};
const SpinnerLoader = React.memo(Spinner);
export default SpinnerLoader;
