import React, { memo, useContext, useMemo } from "react";
import "./header.scss";
import moment from "moment-timezone";
import { TicketContext } from "../../contexts/ticket-context";
import { ThemeContext } from "../../contexts/theme-context";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import LogoThemeDark from "../../assets/images/logo-theme-dark.svg";
import { resizeImage } from "../../utils/helper";

const TIME_EVENT = "December 30-31, 2022";

export interface IHeaderProps { }

const Header = (props: IHeaderProps) => {
  const { event } = useContext(TicketContext);
  const { theme, onChangeTheme } = useContext(ThemeContext);
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const [searchParams] = useSearchParams();
  const { t } = useTranslation();

  const checkScreenPurchaseSuccess = () => {
    const paramsAsObject = Object.fromEntries([...(searchParams as any)]);
    if (paramsAsObject.transactionId) return true;
    return false;
  };

  const checkEventIsEnded = useMemo(() => {
    const current = moment().valueOf();
    const endDate = moment(event.endDate).valueOf();
    if (endDate > current) return false;
    return true;
  }, [event]);

  const ConvertDate = (date: string) => {
    return moment(date).format('HH:mm, DD-MM-YYYY');
  };

  const renderTitle = useMemo(() => {
    return (
      <div className="header-title">
        <p className="header-title-event-name">{event.name}</p>
        <p className="header-title-event-time">
          {checkEventIsEnded
            ? t("eventEnded")
            : checkScreenPurchaseSuccess()
              ? t("thankyou")
              : `${event.tagline ?? ''}\n${ConvertDate(event.startDate)} / ${ConvertDate(event.endDate)}`}
        </p>
      </div>
    );
  }, [event, theme, t]);

  const renderTitlePurchaseSuccess = useMemo(() => {
    return (
      <div className="header-title">
        <p className="header-title-event-name">{event.name}</p>
        <p className="header-title-event-time">
          {t("thankyou")}
        </p>
      </div>
    );
  }, [event, theme, t]);

  return (
    <div className="header">
      <div
        className="header-logo"
      // onClick={onChangeTheme}
      >
        <img
          src={event.logoUrl ? resizeImage(event.logoUrl) : LogoThemeDark}
          alt="Logo"
          className={event.logoUrl ? "header-logo-imageEvent" : "header-logo-default"}
        />
      </div>
      {event._id && !checkScreenPurchaseSuccess() && renderTitle}
      {checkScreenPurchaseSuccess() && renderTitlePurchaseSuccess}
    </div>
  );
};

export default memo(Header);
