import {useContext} from "react";
import {IEvent} from "../../apis/types";
import { ETheme } from "../../constants/theme";
import { ThemeContext } from "../../contexts/theme-context";
import "./item-event.scss";
import moment from "moment-timezone";
import { Link } from "react-router-dom";
import {IconNoEvent} from "../../assets/images";

export interface IItemEventProps {
  event: IEvent;
}

export default function ItemEvent(props: IItemEventProps) {
  const { event } = props;
  const { theme } = useContext(ThemeContext);

  return (
    <Link to={event.url.split('/')[3]} style={{ textDecoration: 'none' }}>
        <div className="item-event">
            <div className="event-image">
                <div className="image-content" style={{background: '#EA5284'}}>
                    <img className="image" src={event.logoUrl ?? IconNoEvent} alt={event.name + ' image'} />
                </div>
            </div>
            <div className="event-information">
                <div className="top-content">
                    <div
                        className={`eventName ${
                            theme !== ETheme.DARK && "eventName-theme"
                        }`}
                    >
                        {event.name}
                    </div>
                    {/*{renderPriceTicket}*/}
                </div>
                <div className="bottom-content">
                    <div
                        className={`description ${
                            theme !== ETheme.DARK && "description-light"
                        }`}
                    >
                        {event.address}
                    </div>
                    <div
                        className={`description ${
                            theme !== ETheme.DARK && "description-light"
                        }`}
                    >
                        {moment(event.startDate).format("HH:mm, MM-DD-YYYY")} / {moment(event.endDate).format("HH:mm, MM-DD-YYYY")}
                    </div>
                </div>
            </div>
        </div>
    </Link>
  );
}
