import React, { useContext, useMemo } from "react";
import "./style.scss";

export default function YesNoComponent(props: any) {
  const { question, optionData, answer } = props.data;
  const { blockIndex, itemIndex, onChange } = props;
  return (
    <div className="wrapperYesNo">
      <div className="questionYesNo">
        {question}
        <span style={{ color: "#EC8297" }}> *</span>
      </div>
      <div className="wrapperButton">
        {optionData.map((item: any) => (
          <div
            className={`buttonYesNo ${answer === item.value ? "active" : "deActive"
              }`}
            onClick={() => {
              onChange(blockIndex, itemIndex, item.value);
            }}
          >
            {item.label}
          </div>
        ))}
      </div>
    </div>
  );
}
