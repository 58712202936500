import { toast } from "react-toastify";
import { EValueLanguage, ILanguage } from "../apis/types";
import { CURRENCY, SYMBOL_CURRENCY } from "../constants/currency";
import { ELanguage } from "../constants/language";
import i18n from "../i18n";

const IGNORE_PHONE_NUMBER = [",", "-", ",", "(", ")", " "];

export function getSafePercent(percent: number) {
  if (percent > 100 || percent < 0 || typeof percent !== "number") {
    console.warn(
      `[react-step-progress-bar]: The value passed to percent or position needs to be a number between 0 and 100 (passed value: ${percent}).`
    );
  }
  return Math.min(100, Math.max(percent, 0));
}

export function getStepPosition(
  steps: number,
  stepIndex: number,
  hasStepZero: boolean
) {
  if (hasStepZero) {
    return (100 / (steps - 1)) * stepIndex;
  }
  return (100 / steps) * (stepIndex + 1);
}

export function makeFormData(details: any) {
  let formBody: any[] = [];
  for (let property in details) {
    let encodedKey = encodeURIComponent(property);
    let encodedValue = encodeURIComponent(details[property]);
    formBody.push(encodedKey + "=" + encodedValue);
  }
  return formBody.join("&");
}

export function nameByLang(data: Array<ILanguage>, value: EValueLanguage) {
  const name = data.find((item) => item.lang === ELanguage.EN);
  if (name && name.content) return name.content;
  else {
    if (value === EValueLanguage.NAME) return "Regular tickets";
    else return "";
  }
}

export const formatCurrency = (value: number) => {
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });
  const price = Math.round((value * 100) / 100).toFixed(2);
  const formatPrice = formatter
    .format(parseInt(price))
    .replace("$", "")
    .replace(",", "")
    .replace(".", ",");
  return formatPrice;
};

export const CapitalizeWord = (mySentence: string) => {
  const words = mySentence.split(" ");

  for (let i = 0; i < words.length; i++) {
    words[i] = words[i][0].toUpperCase() + words[i].substr(1);
  }

  return words.join(" ");
};

export const symbolCurrency = (currency: CURRENCY): SYMBOL_CURRENCY => {
  return SYMBOL_CURRENCY[currency] || SYMBOL_CURRENCY.USD;
};

export const convertPhoneNumber = (phone: string) => {
  const phoneNumber = phone
    .split("")
    .filter((item) => !IGNORE_PHONE_NUMBER.includes(item) && item)
    .join("");
  return phoneNumber;
};

export default function handleServiceError(error: any) {
  let msg = "";
  if (error.response.data.Error || error.response.data.error)
    msg = error.response.data.Error || error.response.data.error;
  if (msg === "Tickets are sold out") msg = i18n.t("toast.ticketSoldOut");
  if (!msg) msg = i18n.t("toast.somethingWrong");
  toast.error(msg);
}

export const fixDecimals = (value: any, decimalPoint = 2) => {
  if (!value) return "0";
  let floatValue = parseFloat(value)
    .toFixed(decimalPoint)
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
  if (decimalPoint == 2) {
    let posiComma = floatValue.length - 3;
    floatValue =
      floatValue.substring(0, posiComma) +
      "," +
      floatValue.substring(posiComma + 1);
  }

  return floatValue.replace(".", "").replace(/\,00$/, "");
};

export const resizeImage = (url: any) => {
  let newStr;
  if (url) {
    const arrStr = url.split('.');
    const newArrStr = [...arrStr];
    newArrStr[arrStr.length - 2] = newArrStr[arrStr.length - 2] + '@4';
    newStr = newArrStr.join('.');
  }
  return newStr;
};


