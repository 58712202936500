import React, {
  useState,
  createContext,
  PropsWithChildren,
  useContext,
} from "react";
import SpinnerLoader from "../components/loader/loader";

export interface ILoadingContext {
  loading: boolean;
  showLoading: () => void;
  hideLoading: () => void;
}

export const LoadingContext = createContext<ILoadingContext>({
  loading: false,
  showLoading: () => {},
  hideLoading: () => {},
});

const LoadingProvider = (props: PropsWithChildren) => {
  const [loading, setLoading] = useState<boolean>(false);
  const contextValue = {
    loading,
    showLoading: () => setLoading(true),
    hideLoading: () => setLoading(false),
  };

  return (
    <LoadingContext.Provider value={contextValue}>
      {loading && <SpinnerLoader />}
      {props.children}
    </LoadingContext.Provider>
  );
};

export default LoadingProvider;
