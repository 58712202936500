import React, { useLayoutEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IcDropdownLanguage } from "../../assets/images";
import { ListLanguage, OptionLanguage } from "../../constants/language";
import "./dropdown.scss";

export default function Dropdown() {
  const [isActive, setIsActive] = useState(false);
  const i18nextLng = localStorage.getItem("i18nextLng") || "en";
  const [selected, setIsSelected] = useState<OptionLanguage>(
    ListLanguage.find((item) => item.code === i18nextLng) || ListLanguage[0]
  );
  const { i18n } = useTranslation();

  useLayoutEffect(() => {
    const dropdownContent = document.getElementById(
      "dropdown-content"
    ) as HTMLElement;
    if (isActive) {
      const rectsDropdownContent =
        dropdownContent?.getClientRects() as DOMRectList;

      const heightDropdownContent = rectsDropdownContent[0]?.height;

      dropdownContent.style.top = -heightDropdownContent - 16 + "px";
    } else {
      dropdownContent.style.removeProperty("top");
    }
  }, [isActive]);

  const onChangeLanguage = (item: OptionLanguage) => {
    setIsSelected(item);
    setIsActive(!isActive);
    i18n.changeLanguage(item.code);
  };

  return (
    <div className="dropdown">
      <div
        onClick={() => {
          setIsActive(!isActive);
        }}
        className="dropdown-btn"
        id="dropdown-btn"
      >
        <img src={selected.icon} />
        <div style={{color: "var(--text-primary)"}}>{selected.language}</div>
        <img src={IcDropdownLanguage} />
      </div>
      <div
        className="dropdown-content"
        style={{ display: isActive ? "block" : "none" }}
        id="dropdown-content"
      >
        {ListLanguage.map((item) => (
          <div
            onClick={() => onChangeLanguage(item)}
            className="item"
            key={item.code}
          >
            <img src={item.icon} />
            <div style={{color: "var(--text-primary)"}}>{item.language}</div>
            <div
              className={`circle-language ${
                selected.language === item.language &&
                "circle-language__selected"
              }`}
            />
          </div>
        ))}
      </div>
    </div>
  );
}
