import { IcEnglish, IcSpanish } from "../assets/images";

export enum ELanguage {
  EN = "EN",
  ES = "ES",
}

export interface OptionLanguage {
  icon: string;
  language: string;
  code: string;
}

export const ListLanguage = [
  { icon: IcEnglish, language: "English", code: "en" },
  { icon: IcSpanish, language: "Español", code: "es" },
];
