import React, { PropsWithChildren, useContext, useEffect } from "react";
import "./layout.scss";
import {EventContext} from "../contexts/event-context";
import {TicketContext} from "../contexts/ticket-context";

export interface ILayoutProps {}

type Props = PropsWithChildren<ILayoutProps>;

export default function Layout(props: Props) {
    const {event} = useContext(TicketContext);
    const {loadedEvents} = useContext(EventContext)

  return (
    <div className="layout">
      <div className={`layout-container ${event._id || loadedEvents ? "layout-border" : ""}`}>{props.children}</div>
    </div>
  );
}
