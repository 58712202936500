import logoThemeOR from '../assets/images/logoThemeOR.png'
import backgroundThemeOR from '../assets/images/backgroundOR.png'

type ObjectThemes = {
    [key: string]: string;
}

export const logoTheme: ObjectThemes = {
    OR: logoThemeOR,
}

export const backgroundTheme: ObjectThemes = {
    OR: backgroundThemeOR,
}
export const fontFamilyTheme: ObjectThemes = {
    OR: 'LouisCafe',
}

export const getLogoTheme = (theme: keyof ObjectThemes): string => {
   return logoTheme[theme];
}

export const getBackgroundTheme = (theme: keyof ObjectThemes): string => {
    return backgroundTheme[theme];
 }

export const getFontFamilyTheme = (theme: keyof ObjectThemes): string => {
    return fontFamilyTheme[theme];
 }
 

