import httpRequest from "./httpRequest";
import {
  IRequestLocketTicket,
  IRequestTicketFiat,
  IResponseFiatTicket,
  IResponseLockTicket,
  ITicketResponse,
  IRequestPurchaseTicket,
  IResponsePurchaseTicket,
  IRequestUpcomingEvents, IResponseUpcomingEvents,
} from "./types";

const baseApi = "/eventmanager";

export const fetchTicketEventByUrl = async (url: string) => {
  try {
    const body = { url };
    const response = await httpRequest.post<ITicketResponse>(
      `${baseApi}/tickets/get-by-url`,
      body
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const lockTicketFn = async (body: IRequestLocketTicket) => {
  try {
    const response = await httpRequest.post<Array<IResponseLockTicket>>(
      `${baseApi}/tickets/lock-tickets`,
      body
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fiatGatewayFn = async (body: IRequestTicketFiat) => {
  try {
    const response = await httpRequest.post<IResponseFiatTicket>(
      `fiatgateway/topup/ticket`,
      body
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchTokenPriceWithEURFn = async () => {
  try {
    const response = await httpRequest.get<Number>(
      `fiatgateway/sdr/EUR/convert-to-tokens?amount=1`
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const fetchTokenPriceWithUSDFn = async () => {
  try {
    const response = await httpRequest.get<Number>(
      `fiatgateway/sdr/USD/convert-to-tokens?amount=1`
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const requestPurchaseTicketFn = async (body: IRequestPurchaseTicket) => {
  try {
    const response = await httpRequest.post<IResponsePurchaseTicket>(
      "eventmanager/tickets/request-purchase-web",
      body
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchEventsByDate = async (body: IRequestUpcomingEvents) => {
  try {
    const response = await httpRequest.post<IResponseUpcomingEvents>(
      `eventmanager/events/search-published-events?limit=${body.limit}&page=${body.page}`,
      body
    );
    return response.data;
  } catch (error) {
    throw error;
  }
}
