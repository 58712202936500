import { createGlobalStyle } from "styled-components";
import FontLatoChi from "../assets/fonts/LatoCHI-Regular.ttf";
import FontBabes from "../assets/fonts/BebasNeue-Regular.ttf";
import FontCourier from "../assets/fonts/CourierPrime-Regular.ttf";
import Gasolyn from "../assets/fonts/Gasolyn-Stamp.ttf";
import LouisCafe from "../assets/fonts/Louis-George-Cafe.ttf";
import { ETheme } from "../constants/theme";
import { getFontFamilyTheme } from "../constants/themeGlobal";
export const GlobalStyle = createGlobalStyle<{ theme: ETheme }>`
  * {
      box-sizing: border-box;
      padding: 0;
      margin: 0;
  }
  body {
    font-family: ${(props) =>
      props.theme === ETheme.DARK ? "Lato" : getFontFamilyTheme(props.theme)};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  @font-face {
    font-family: 'Lato';
    src: url(${FontLatoChi}) format('truetype');
    font-style: normal;
  }
  @font-face {
    font-family: 'Bebas Neue';
    src: url(${FontBabes}) format('truetype');
    font-style: normal;
  }  
  @font-face {
    font-family: 'Courier Prime';
    src: url(${FontCourier}) format('truetype');
    font-style: normal;
  }
  @font-face {
    font-family: 'Gasolyn';
    src: url(${Gasolyn}) format('truetype');
    font-style: normal;
  }
  @font-face {
    font-family: 'LouisCafe';
    src: url(${LouisCafe}) format('truetype');
    font-style: normal;
  }
`;
