import React, { useEffect, useState } from "react";
import "./select.scss";
import IconDropdown from "../../../assets/images/IconDropdown.png";
import IconCheck from "../../../assets/images/IconCheckSelected.png";

export default function SelectInput(props: any) {
  const [isExpand, setIsExpand] = useState(false);
  const { question, optionData, answer, placeholder } = props.data;
  const { blockIndex, itemIndex, onChange } = props;

  const handleToggle = () => {
    setIsExpand(!isExpand);
  };

  const handleSelect = (value: any) => {
    handleToggle();
    onChange(blockIndex, itemIndex, value);
  };

  useEffect(() => {
    const myDiv = document.getElementById(`select-${blockIndex}-${itemIndex}`);
    const handleEvent = (event: any) => {
      const target = event.target;
      if (myDiv) {
        if (!myDiv.contains(target)) {
          // console.log("Clicked outside the div");
          setIsExpand(false);
        }
      }
    };
    if (myDiv) {
      document.addEventListener("click", handleEvent);
    }
    return () => {
      document.removeEventListener("click", handleEvent);
    };
  });

  return (
    <div
      id={`select-${blockIndex}-${itemIndex}`}
      className="wrapperSelectButton"
    >
      <div className="questionSelect">
        {question}
        <span style={{ color: "#EC8297" }}> *</span>{" "}
      </div>
      <div
        className={`form-select-button ${!answer && "placeHolder"}`}
        onClick={handleToggle}
      >
        <span>{answer || placeholder}</span>
        <img src={IconDropdown} style={{ width: 12, height: 6 }} />
      </div>
      {isExpand && (
        <div className="wrapperOptionSelect">
          {optionData.map((item: any) => (
            <div
              onClick={() => {
                handleSelect(item.value);
              }}
              className="optionSelect"
            >
              <div>{item.label}</div>
              {answer === item.value && <img src={IconCheck} />}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
