import React, { useContext, useMemo } from "react";
import "./checkbox.scss";

export default function CheckboxComponent(props: any) {
  const { questionComponent, answer } = props.data;
  const { blockIndex, itemIndex, onChange } = props;

  const handleCheckbox = (event: any) => {
    onChange(blockIndex, itemIndex, event.target.checked);
  };
  return (
    <div className="wrapperCheckbox">
      <div className="inputCheckbox">
        <label className="container">
          <input type="checkbox" checked={answer} onChange={handleCheckbox} />
          <span className="checkmark"></span>
        </label>
        <div className="textCheckbox">{questionComponent}</div>
      </div>
    </div>
  );
}
