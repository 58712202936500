export const initStepQuestion = [
  [
    {
      question: "Are you sharing a tent?",
      type: "yesNo",
      answer: null,
      optionData: [
        { label: "Yes", value: "Yes" },
        { label: "No", value: "No" },
      ],
    },
    {
      question: "Is it a partner or friend?",
      type: "selection",
      placeholder: "Selection",
      answer: null,
      optionData: [
        { label: "Partner", value: "Partner" },
        { label: "Friend", value: "Friend" },
      ],
    },
    {
      question: "What is their name?",
      type: "textInput",
      placeholder: "Full name",
      answer: "",
    },
    {
      type: "text",
      answer: "If not, we will match you with someone we think you’ll like.",
    },
  ],
  [
    {
      question:
        "Do you have any serious medical needs that we should know about?",
      type: "areaInput",
      answer: "",
    },
  ],
  [
    {
      question: "Donations will be matched! Select one of OUR five impact organizations and help us reach our goal! Every bit counts.",
      type: "selection",
      placeholder: "Select organization",
      answer: null,
      optionData: [
        { label: "Despri-Haiti", value: "Despri-Haiti" },
        { label: "Global Coralition", value: "Global Coralition" },
        { label: "Charity Water", value: "Charity Water" },
        { label: "No Hungry Children", value: "No Hungry Children" },
        { label: "Dreamweaver Foundation", value: "Dreamweaver Foundation" },
      ],
    },
  ],
  [
    {
      question: "I agree with this NDA & Liability Waiver before purchasing your ticket.",
      type: "checkbox",
      answer: true,
      questionComponent: (
        <p>
          I agree with this NDA &{" "}
          <a
            href="https://cdn.chi.app/Liabiltity__NDA.pdf"
            target="blank"
            className="linkTerm"
          >
            Liability
          </a>{" "}
          Waiver before purchasing your ticket.
        </p>
      ),
    },
  ],
];