import React, { useContext } from "react";
import { ETheme } from "../../constants/theme";
import { ThemeContext } from "../../contexts/theme-context";
import "./title.scss";

export interface ITitleComponentProps {
  text: string;
  additionClass?: string;
}

export default function TitleComponent(props: ITitleComponentProps) {
  const { text, additionClass } = props;
  const { theme } = useContext(ThemeContext);
  return (
    <p className={`title ${theme !== ETheme.DARK && "title-light"} ${additionClass ?? ""}`}>{text}</p>
  );
}
