import React, { useContext } from "react";
import { ETheme } from "../../constants/theme";
import { ThemeContext } from "../../contexts/theme-context";
import "./button.scss";

export interface IButtonComponentProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  text: string;
}

export default function ButtonComponent(props: IButtonComponentProps) {
  const { text, ...buttonProps } = props;
  const { theme } = useContext(ThemeContext);
  return (
    <button
      {...buttonProps}
      className={`button ${theme === ETheme.LIGHT && "button-light"}`}
    >
      {text}
    </button>
  );
}
