import React, { useContext, useEffect } from "react";
import { BackgroundDark } from "./assets/images";
import { ETheme } from "./constants/theme";
import Main from "./containers";
import { ThemeContext } from "./contexts/theme-context";
import Layout from "./layouts/layout";
import { GlobalStyle } from "./styles/globalStyles";
import { getBackgroundTheme } from "./constants/themeGlobal";
import SpinnerLoader from "./components/loader/loader";
import { TicketContext } from "./contexts/ticket-context";
import {Helmet} from "react-helmet";
import "vanilla-cookieconsent/dist/cookieconsent.css";
import * as CookieConsent from "vanilla-cookieconsent";
import ReactGA from "react-ga4";

function App(props: any) {
  const { theme, name, description, url } = useContext(ThemeContext);
  const { event } = useContext(TicketContext);

  useEffect(() => {
    CookieConsent.run({

      cookie: {
          name: 'chi_ticket_cc_cookie',
          expiresAfterDays: 180
      },

      guiOptions: {
          consentModal: {
            layout: 'box',
            position: 'bottom',
            flipButtons: false,
            equalWeightButtons: true
          },
          preferencesModal: {
              layout: 'box',
              position: 'left',
              flipButtons: false
          }
      },

      onFirstConsent: () => {
          console.log('onFirstAction fired');
      },

      onConsent: () => {
          console.log('onConsent fired ...');
          if(CookieConsent.acceptedCategory('analytics')){
            // Analytics category enabled
            if (process.env.GA_MEASUREMENT_ID)
              ReactGA.initialize(process.env.GA_MEASUREMENT_ID, {testMode: false});
          }
      },

      onChange: ({changedCategories}) => {
          if(changedCategories.includes('analytics')){
            console.log('analytics enabled');
            if(CookieConsent.acceptedCategory('analytics')) {
                // Analytics category was just enabled
                if (process.env.GA_MEASUREMENT_ID)
                  ReactGA.initialize(process.env.GA_MEASUREMENT_ID, {testMode: false});
            }else {
                // Analytics category was just disabled
                console.log('analytics disable accepted category===');
            }
        }
      },

      categories: {
          necessary: {
              readOnly: true,
              enabled: true
          },
          analytics: {
              autoClear: {
                  cookies: [
                      {
                          name: /^(_ga|_gid)/
                      }
                  ]
              }
          }
      },

      language: {
          default: 'en',

          translations: {
              en: {
                  consentModal: {
                      title: 'We value your privacy',
                      description: 'Our website uses tracking cookies to understand how you interact with it. The tracking will be enabled only if you accept explicitly. <a href="#privacy-policy" data-cc="show-preferencesModal" class="cc__link">Manage preferences</a>',
                      acceptAllBtn: 'Accept all',
                      acceptNecessaryBtn: 'Reject all',
                      footer: `
                          <a href="https://cdn.chi.app/privacystatement.pdf">Privacy Policy</a>
                          <a href="https://cdn.chi.app/termcondition.pdf">Terms and Conditions</a>
                      `
                  },
                  preferencesModal: {
                    title: "Cookie preferences",
                    acceptAllBtn: "Accept all",
                    acceptNecessaryBtn: "Reject all",
                    savePreferencesBtn: "Save preferences",
                    closeIconLabel: "Close",
                    sections: [
                        {
                            title: "Cookie usage",
                            description: "We use cookies to ensure the basic functionalities of the website and to enhance your online experience ..."
                        },
                        {
                            title: "Strictly necessary cookies",
                            description: "These cookies are essential for the proper functioning of my website. Without these cookies, the website would not work properly",
                            linkedCategory: "necessary"
                        },
                        {
                            title: "Performance and Analytics cookies",
                            description: "These cookies allow the website to remember the choices you have made in the past",
                            linkedCategory: "analytics",
                            cookieTable: {
                                headers: {
                                    name: "Name",
                                    domain: "Service",
                                    description: "Description",
                                    expiration: "Expiration"
                                },
                                body: [
                                    {
                                        name: "_ga",
                                        domain: "Google Analytics",
                                        description: "Cookie set by <a href=\"#das\">Google Analytics</a>",
                                        expiration: "Expires after 180 days"
                                    }
                                ]
                            }
                        },
                        {
                            title: "More information",
                            description: "For any queries in relation to our policy on cookies and your choices, please <a class=\"cc-link\" href=\"#chi.app\">contact us</a>."
                        }
                    ]
                }
              }
          }
      }
  });
  }, []);

  // console.log('AAAAAAA: ', event)
  useEffect(() => {
    // Update og:image property
    const ogImageMeta = document.querySelector('meta[property="og:image"]');
    ogImageMeta?.setAttribute('content', `${event?.logoUrl || ''}`);

    // Update sapple-touch-icon link
    const appleTouchIconLink = document.querySelector('link[rel="apple-touch-icon"]');
    appleTouchIconLink?.setAttribute('href', `${event?.logoUrl || ""}`);
  }, [event]);
  return (
    <div
      className="container"
      style={{
        backgroundImage:
          theme === ETheme.DARK
            ? `url(${BackgroundDark})`
            : `url(${getBackgroundTheme(theme)})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "100% 100%",
        backgroundAttachment: "fixed",
      }}
    >
        <Helmet>
                <meta charSet="utf-8" />
                <title>{`${name} Official online ticket sale`}</title>
                <link rel="canonical" href={url} />
                <meta name="description" content={description} />
        </Helmet>
      <GlobalStyle theme={theme} />
      {theme ? (
        <>
          <Layout>
            <Main />
          </Layout>
        </>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "100%",
          }}
        >
          <SpinnerLoader />
        </div>
      )}
    </div>
  );
}

export default App;
