import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { ETheme } from "../../constants/theme";
import { ThemeContext } from "../../contexts/theme-context";
import "./payment-service.scss";

export default function PaymentService() {
  const { theme } = useContext(ThemeContext);
  const { t } = useTranslation();
  return (
    <div
      className={`payment-service ${
        theme === ETheme.LIGHT && "payment-service-light"
      }`}
    >
      {t("paymentService")}
    </div>
  );
}
