import * as React from "react";
import { toast } from "react-toastify";

export enum EToast {
  SUCCESS = "success",
  ERROR = "error",
}

export default function useToast() {
  const addToast = (status: EToast, content: string) => {
    toast[status](content, { position: toast.POSITION.TOP_RIGHT });
  };
  return addToast;
}
