export enum CURRENCY {
  AED = "AED",
  AFN = "AFN",
  ALL = "ALL",
  AMD = "AMD",
  ANG = "ANG",
  AOA = "AOA",
  ARS = "ARS",
  AUD = "AUD",
  AWG = "AWG",
  AZN = "AZN",
  BAM = "BAM",
  BBD = "BBD",
  BDT = "BDT",
  BGN = "BGN",
  BHD = "BHD",
  BIF = "BIF",
  BMD = "BMD",
  BND = "BND",
  BOB = "BOB",
  BRL = "BRL",
  BSD = "BSD",
  BTC = "BTC",
  BTN = "BTN",
  BWP = "BWP",
  BYN = "BYN",
  BYR = "BYR",
  BZD = "BZD",
  CAD = "CAD",
  CDF = "CDF",
  CHF = "CHF",
  CLF = "CLF",
  CLP = "CLP",
  CNY = "CNY",
  COP = "COP",
  CRC = "CRC",
  CUC = "CUC",
  CUP = "CUP",
  CVE = "CVE",
  CZK = "CZK",
  DJF = "DJF",
  DKK = "DKK",
  DOP = "DOP",
  DZD = "DZD",
  EGP = "EGP",
  ERN = "ERN",
  ETB = "ETB",
  EUR = "EUR",
  FJD = "FJD",
  FKP = "FKP",
  GBP = "GBP",
  GEL = "GEL",
  GGP = "GGP",
  GHS = "GHS",
  GIP = "GIP",
  GMD = "GMD",
  GNF = "GNF",
  GTQ = "GTQ",
  GYD = "GYD",
  HKD = "HKD",
  HNL = "HNL",
  HRK = "HRK",
  HTG = "HTG",
  HUF = "HUF",
  IDR = "IDR",
  ILS = "ILS",
  IMP = "IMP",
  INR = "INR",
  IQD = "IQD",
  IRR = "IRR",
  ISK = "ISK",
  JEP = "JEP",
  JMD = "JMD",
  JOD = "JOD",
  JPY = "JPY",
  KES = "KES",
  KGS = "KGS",
  KHR = "KHR",
  KMF = "KMF",
  KPW = "KPW",
  KRW = "KRW",
  KWD = "KWD",
  KYD = "KYD",
  KZT = "KZT",
  LAK = "LAK",
  LBP = "LBP",
  LKR = "LKR",
  LRD = "LRD",
  LSL = "LSL",
  LTL = "LTL",
  LVL = "LVL",
  LYD = "LYD",
  MAD = "MAD",
  MDL = "MDL",
  MGA = "MGA",
  MKD = "MKD",
  MMK = "MMK",
  MNT = "MNT",
  MOP = "MOP",
  MRO = "MRO",
  MUR = "MUR",
  MVR = "MVR",
  MWK = "MWK",
  MXN = "MXN",
  MYR = "MYR",
  MZN = "MZN",
  NAD = "NAD",
  NGN = "NGN",
  NIO = "NIO",
  NOK = "NOK",
  NPR = "NPR",
  NZD = "NZD",
  OMR = "OMR",
  PAB = "PAB",
  PEN = "PEN",
  PGK = "PGK",
  PHP = "PHP",
  PKR = "PKR",
  PLN = "PLN",
  PYG = "PYG",
  QAR = "QAR",
  RON = "RON",
  RSD = "RSD",
  RUB = "RUB",
  RWF = "RWF",
  SAR = "SAR",
  SBD = "SBD",
  SCR = "SCR",
  SDG = "SDG",
  SEK = "SEK",
  SGD = "SGD",
  SHP = "SHP",
  SLL = "SLL",
  SOS = "SOS",
  SRD = "SRD",
  STD = "STD",
  SVC = "SVC",
  SYP = "SYP",
  SZL = "SZL",
  THB = "THB",
  TJS = "TJS",
  TMT = "TMT",
  TND = "TND",
  TOP = "TOP",
  TRY = "TRY",
  TTD = "TTD",
  TWD = "TWD",
  TZS = "TZS",
  UAH = "UAH",
  UGX = "UGX",
  USD = "USD",
  UYU = "UYU",
  UZS = "UZS",
  VEF = "VEF",
  VND = "VND",
  VUV = "VUV",
  WST = "WST",
  XAF = "XAF",
  XAG = "XAG",
  XAU = "XAU",
  XCD = "XCD",
  XDR = "XDR",
  XOF = "XOF",
  XPF = "XPF",
  YER = "YER",
  ZAR = "ZAR",
  ZMK = "ZMK",
  ZMW = "ZMW",
  ZWL = "ZWL",
}

export enum SYMBOL_CURRENCY {
  AED = "د.إ",
  AFN = "؋",
  ALL = "L",
  AMD = "֏",
  ANG = "ƒ",
  AOA = "Kz",
  ARS = "$",
  AUD = "$",
  AWG = "ƒ",
  AZN = "₼",
  BAM = "KM",
  BBD = "$",
  BDT = "৳",
  BGN = "лв",
  BHD = "ب.د",
  BIF = "Fr",
  BMD = "$",
  BND = "$",
  BOB = "$b",
  BRL = "R$",
  BSD = "$",
  BTC = "₿",
  BTN = "Nu.",
  BWP = "P",
  BYN = "Br",
  BYR = "Br",
  BZD = "BZ$",
  CAD = "$",
  CDF = "Fr",
  CHF = "CHF",
  CLF = "UF",
  CLP = "$",
  CNY = "¥",
  COP = "$",
  CRC = "₡",
  CUC = "$",
  CUP = "₱",
  CVE = "Esc",
  CZK = "Kč",
  DJF = "Fdj",
  DKK = "kr.",
  DOP = "RD$",
  DZD = "د.ج",
  EGP = "£",
  ERN = "Nfk",
  ETB = "Br",
  EUR = "€",
  FJD = "$",
  FKP = "£",
  GBP = "£",
  GEL = "₾",
  GGP = "£",
  GHS = "₵",
  GIP = "£",
  GMD = "D",
  GNF = "FG",
  GTQ = "Q",
  GYD = "$",
  HKD = "$",
  HNL = "L",
  HRK = "kn",
  HTG = "G",
  HUF = "Ft",
  IDR = "Rp",
  ILS = "₪",
  IMP = "£",
  INR = "₹",
  IQD = "ع.د",
  IRR = "﷼",
  ISK = "kr",
  JEP = "£",
  JMD = "J$",
  JOD = "JD",
  JPY = "¥",
  KES = "KSh",
  KGS = "лв",
  KHR = "៛",
  KMF = "CF",
  KPW = "₩",
  KRW = "₩",
  KWD = "KD",
  KYD = "$",
  KZT = "лв",
  LAK = "₭",
  LBP = "£",
  LKR = "₨",
  LRD = "$",
  LSL = "M",
  LTL = "Lt",
  LVL = "Ls",
  LYD = "LD",
  MAD = "MAD",
  MDL = "lei",
  MGA = "MGA",
  MKD = "ден",
  MMK = "K",
  MNT = "₮",
  MOP = "MOP$",
  MRO = "UM",
  MUR = "₨",
  MVR = "Rf",
  MWK = "MK",
  MXN = "MXN",
  MYR = "RM",
  MZN = "MT",
  NAD = "$",
  NGN = "₦",
  NIO = "C$",
  NOK = "kr",
  NPR = "₨",
  NZD = "$",
  OMR = "﷼",
  PAB = "B/.",
  PEN = "S/.",
  PGK = "K",
  PHP = "₱",
  PKR = "₨",
  PLN = "zł",
  PYG = "Gs",
  QAR = "﷼",
  RON = "lei",
  RSD = "Дин.",
  RUB = "₽",
  RWF = "R₣",
  SAR = "﷼",
  SBD = "$",
  SCR = "₨",
  SDG = "ج.س.",
  SEK = "kr",
  SGD = "$",
  SHP = "£",
  SLL = "Le",
  SOS = "Sh",
  SRD = "$",
  STD = "Db",
  SVC = "₡",
  SYP = "£",
  SZL = "E",
  THB = "฿",
  TJS = "ЅМ",
  TMT = "m",
  TND = "د.ت",
  TOP = "T$",
  TRY = "₺",
  TTD = "TT$",
  TWD = "NT$",
  TZS = "TSh",
  UAH = "₴",
  UGX = "USh",
  USD = "$",
  UYU = "$U",
  UZS = "лв",
  VEF = "Bs",
  VND = "₫",
  VUV = "VT",
  WST = "WS$",
  XAF = "FCFA",
  XAG = "oz t",
  XAU = "oz t",
  XCD = "$",
  XDR = "SDR",
  XOF = "CFA",
  XPF = "₣",
  YER = "﷼",
  ZAR = "R",
  ZMK = "ZK",
  ZMW = "ZK",
  ZWL = "Z$",
}
