import * as React from "react";
import { Route, Routes } from "react-router-dom";
import App from "../App";

export default function RoutesComponent() {
  return (
    <Routes>
      <Route path="/*" element={<App />}>
        <Route path=":id" element={<App />} />
      </Route>
    </Routes>
  );
}
