import React from "react";
import { useTranslation } from "react-i18next";
import TitleComponent from "../../components/title/title";
import "./question.css";
import SelectInput from "../../components/TypeQuestion/selectInput/SelectInput";
import YesNoComponent from "../../components/TypeQuestion/yesNoQuestion";
import CheckboxComponent from "../../components/TypeQuestion/checkbox";
import TextInput from "../../components/TypeQuestion/textInput/textInput";
import TextArea from "../../components/TypeQuestion/AreaInput/area";
import Cart from "../../components/cart/cart";

export default function QuestionsInformation(props: any) {
  const { stepQuestions, handleChangeValueQuestion } = props;
  const { t } = useTranslation();

  const renderQuestion = (item: any, blockIndex: number, itemIndex: number) => {
    const isHideSelectInput =
      blockIndex === 0 &&
      (stepQuestions[0][0].answer === "No" || !stepQuestions[0][0].answer);
    switch (item.type) {
      case "yesNo":
        return (
          <YesNoComponent
            data={item}
            blockIndex={blockIndex}
            itemIndex={itemIndex}
            onChange={handleChangeValueQuestion}
          />
        );
      case "selection":
        return (
          !isHideSelectInput && (
            <SelectInput
              data={item}
              blockIndex={blockIndex}
              itemIndex={itemIndex}
              onChange={handleChangeValueQuestion}
            />
          )
        );

      case "textInput":
        return (
          !isHideSelectInput && (
            <TextInput
              data={item}
              blockIndex={blockIndex}
              itemIndex={itemIndex}
              onChange={handleChangeValueQuestion}
            />
          )
        );
      case "areaInput":
        return (
          <TextArea
            data={item}
            blockIndex={blockIndex}
            itemIndex={itemIndex}
            onChange={handleChangeValueQuestion}
          />
        );

      case "checkbox":
        return (
          <CheckboxComponent
            data={item}
            blockIndex={blockIndex}
            itemIndex={itemIndex}
            onChange={handleChangeValueQuestion}
          />
        );
      case "text":
        return <div className="onlyText">{item.answer}</div>;
      default:
        break;
    }
  };

  return (
    <>
      <div className="questions-ticket-header">
        <div className="ticket-header">
          <div className="left-ticket-header">
            <TitleComponent text={"Questions"} />
          </div>
          <div className="right-ticket-header">
            <Cart />
          </div>
        </div>
      </div>
      {stepQuestions.map((items: any, blockIndex: any) => (
        <div className="wrapperBox">
          {items.map((item: any, itemIndex: any) =>
            renderQuestion(item, blockIndex, itemIndex)
          )}
        </div>
      ))}
      {/* <SelectInput value="FRIEND" placeholder="SELECTION" dataOption={data} /> */}
    </>
  );
}
